const middleware = {}

middleware['clearValidationErrors'] = require('../middleware/clearValidationErrors.js')
middleware['clearValidationErrors'] = middleware['clearValidationErrors'].default || middleware['clearValidationErrors']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['onlyOndernemers'] = require('../middleware/onlyOndernemers.js')
middleware['onlyOndernemers'] = middleware['onlyOndernemers'].default || middleware['onlyOndernemers']

middleware['onlyOrganisaties'] = require('../middleware/onlyOrganisaties.js')
middleware['onlyOrganisaties'] = middleware['onlyOrganisaties'].default || middleware['onlyOrganisaties']

middleware['ssrHeaders'] = require('../middleware/ssrHeaders.js')
middleware['ssrHeaders'] = middleware['ssrHeaders'].default || middleware['ssrHeaders']

export default middleware
