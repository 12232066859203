<template>
  <v-app>
    <skip-content/>
    <app-header/>
    <v-content id="main" tabindex="-1">
      <nuxt />
    </v-content>
    <app-footer/>
  </v-app>
</template>

<script>
import Footer from '~/components/Footer';
import SkipContent from '~/components/SkipContent';
import Header from '~/components/Header';

export default {
  components: {
    'app-footer': Footer,
    'app-header': Header,
    SkipContent,
  },
};
</script>
