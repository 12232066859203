<template>
  <transition name="fade">
    <div v-if="visible" class="cookiebar">
      <v-container>
        <v-layout row wrap justify-space-between align-center cookiebar-content pa-3>
          <v-flex md8 lg10>
            <p :class="$vuetify.breakpoint.smAndDown.isMounted ? 'mb-4' : 'mb-0'">Deze website maakt gebruik van cookies. Bij gebruik van deze website ga je akkoord met de cookies.
              <nuxt-link to="/privacyverklaring">
                Meer informatie over de cookies
              </nuxt-link>
            </p>
          </v-flex>
          <v-flex md4 lg2 class="text-md-right">
            <v-btn round color="accent" large depressed class="ma-0" nuxt @click="close">
              Verberg melding
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </transition>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    if (!this.$cookies.get('cookies')) {
      this.visible = true;
    }
  },
  methods: {
    close() {
      this.$cookies.set('cookies', true, {maxAge: 60 * 60 * 24 * 365});
      this.visible = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
  @import '~assets/style/colors'

    .cookiebar
        position fixed
        left 0
        right 0
        bottom 0
        z-index 100

        .cookiebar-content
            font-size 1rem
            color white
            background primary

        a
            color white
            text-decoration underline

            &:hover, &:focus, &:active, &:active:focus
                color white
                text-decoration none

</style>
