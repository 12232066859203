<template>
  <v-dialog v-model="dialog" width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Tijdelijke maatregelen</span>
      </v-card-title>
      <v-card-text>
        Momenteel is ons bezoekadres gesloten in verband met de preventieve maatregelen met betrekking tot het
        Corona-virus. U kunt wel gebruik blijven maken van onze website en daarnaast kunt u ons ook bereiken via
        telefoon, mail en contactformulier.
      </v-card-text>
      <v-card-actions class="px-3 pb-3 pt-0">
        <v-spacer/>
        <v-btn round depressed large color="accent" @click="dialog = false">Sluiten</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data: () => ({
    dialog: false,
  }),
  computed: mapState(['coronaDialogSeen']),
  watch: {
    dialog(closing) {
      if (closing) {
        this.$store.commit('CLOSE_CORONA_DIALOG');
      }
    },
  },
  mounted() {
    if (!this.coronaDialogSeen) {
      this.dialog = true;
    }
  },
};
</script>
