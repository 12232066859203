<template>
  <v-layout mb-3 hidden-print>
    <v-flex class="py-0">
      <v-breadcrumbs :items="items" class="pa-0">
        <template slot="item" slot-scope="props">
          <nuxt-link :to="props.item.to" :disabled="props.item.disabled" :class="`body-1 v-breadcrumbs__item ${[props.item.disabled && 'v-breadcrumbs__item--disabled']}`">{{ props.item.text.split('?')[0] }}</nuxt-link>
        </template>
      </v-breadcrumbs>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  computed: {
    items() {
      let items = [
        {
          text: 'Je bent hier',
          to: this.$nuxt.$route.path,
          disabled: true,
        },
      ];

      let segments = this.$route.fullPath.replace(/^\//, '').split('/');
      let chain = [];

      segments.map((item, i) => {
        // Skip token segment
        if (i === 2 && segments[0] === 'wachtwoord-vergeten' && segments[1] === 'reset') {
          return;
        }
        if (i === 1 && segments[0] === 'wachtwoord-instellen') {
          return;
        }

        if (i < segments.length - 1) {
          chain.push(item);

          items.push({
            text: item.replace(/-|_/g, ' '),
            to: '/' + chain.join('/'),
          });
        } else {
          items.push({
            text: this.title ? this.title : item.replace(/-|_/g, ' '),
            to: this.$nuxt.$route.path,
            disabled: true,
          });
        }
      });

      return items;
    },
  },
};
</script>

<style lang="stylus" scoped>
  .v-breadcrumbs__item
    display inline-block

    &::first-letter
      text-transform capitalize

</style>
