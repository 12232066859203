<template>
  <v-navigation-drawer v-model="drawerActive" app fixed temporary class="bg-grey-light nav">
    <v-list class="pt-0">
      <template v-for="(item, index) in items">
        <v-list-tile :to="item.name" :key="item.label" color="primary">
          <v-layout align-center>
            <v-list-tile-title>{{ item.label }}</v-list-tile-title>
            <v-icon color="primary">far fa-long-arrow-alt-right</v-icon>
          </v-layout>
        </v-list-tile>
        <v-divider v-if="index < items.length -1" :key="index" class="primary-light mx-3"/>
      </template>
      <v-divider class="white thick"/>
      <v-list-tile :to="{name: 'dashboard'}" nuxt color="primary">
        <v-layout align-center>
          <v-list-tile-title class="font-weight-bold" ><v-icon class="mr-2" color="primary" small>fas fa-user</v-icon>Inloggen</v-list-tile-title>
          <v-icon color="primary">far fa-long-arrow-alt-right</v-icon>
        </v-layout>
      </v-list-tile>
      <v-divider class="primary-light mx-3"/>
      <v-list-tile :to="{name: 'organisaties'}" color="primary" nuxt>
        <v-layout align-center>
          <v-list-tile-title>Voor organisaties en initiatieven</v-list-tile-title>
          <v-icon color="primary">far fa-long-arrow-alt-right</v-icon>
        </v-layout>
      </v-list-tile>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data() {
    return {
      items: [
        {
          label: 'Home',
          name: '/',
        },
        {
          label: 'Vacatures',
          name: '/vacatures',
        },
        {
          label: 'Academie',
          name: '/academie',
        },
        {
          label: 'Nieuws',
          name: '/nieuws',
        },
        {
          label: 'Kennisbank',
          name: '/kennisbank',
        },
        {
          label: 'Contact',
          name: '/contact',
        },
      ],
      menu: [
        {
          label: 'Profiel bewerken',
          slug: '/profiel',
        },
        {
          label: 'Instellingen',
          slug: '/instellingen',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      drawer: state => state.drawer,
    }),
    drawerActive: {
      set(drawerActive) {
        this.$store.commit('SET_DRAWER', drawerActive);
      },
      get() {
        return this.drawer;
      },
    },
  },
};
</script>

<style lang="stylus" scoped>
  @import '~assets/style/colors'

  header
    position -webkit-sticky
    position sticky
    top 0
    z-index 20

    >>> .v-toolbar__content
      height auto !important
      padding-left 0
      padding-right 0


  a.no-underline
    text-decoration none

  .nuxt-link-exact-active span
    border-bottom 2px primary solid
    font-weight bold

  .text-field
    >>> .v-input__slot
      padding 4px 0 8px
      &::before
        border none !important
    >>> label
      color primary-light

  .accordion >>>
    .v-expansion-panel__header, .v-expansion-panel__body
      background-color grey-light
      padding-top 0
      padding-bottom 0

  .nav
    padding-top 112px

    >>> .v-list__tile--active
      font-weight 700


  .toolbar >>> .v-toolbar__content
    justify-content center

  .transition-none
    transition none !important

  .icon-small
    font-size 20px

  hr.thick
    border-width 3px 0 0 0
</style>
