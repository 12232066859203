export const state = () => ({
  filter: {
    audiences: [],
    types: [],
    gemeentes: [],
    areas: [],
    ranges: [],
    searches: [],
    periods: [],
    page: 1,
  },
  items: [],
  total: 0,
  pageLimit: 0,
  filterActive: false,
});

export const actions = {
  resetFilters({commit}) {
    commit('SET_AUDIENCES', []);
    commit('SET_TYPES', []);
    commit('SET_GEMEENTES', []);
    commit('SET_AREAS', []);
    commit('SET_RANGES', []);
    commit('SET_SEARCHES', []);
    commit('SET_PERIODS', []);
    commit('SET_PAGE_NR', 1);
    commit('SET_ACTIVE', false);
  },
  async getVacancies({commit, app, state}) {
    let url = `/vacatures?page=${state.filter.page}`;

    if (state.filter.ranges.length) {
      let minHours = state.filter.ranges[0];
      let maxHours = state.filter.ranges[1];

      if (minHours !== this.min || maxHours !== this.max) {
        url += `&minHours=${minHours}&maxHours=${maxHours}`;
      }
    }

    if (state.filter.searches.length) {
      url += `&s=${encodeURIComponent(state.filter.searches)}`;
    }

    if (state.filter.audiences.length) {
      url += `&audience=${state.filter.audiences.join('|')}`;
    }
    if (state.filter.types.length) {
      url += `&type=${state.filter.types.join('|')}`;
    }
    if (state.filter.gemeentes.length) {
      url += `&gemeentes=${state.filter.gemeentes.join('|')}`;
    }
    if (state.filter.areas.length) {
      url += `&area=${state.filter.areas.join('|')}`;
    }
    if (state.filter.periods.length) {
      url += `&period=${state.filter.periods.join('|')}`;
    }

    try {
      const {data, headers} = await this.$axios.get(url);

      commit('SET_VACANCIES', data);
      commit('SET_TOTAL', headers);
      commit('SET_PAGE_LIMIT', headers);
      commit('SET_ACTIVE', true);
    } catch (e) {
      app.$bugsnag.notify(e);
    }
  },
};

export const mutations = {
  SET_AUDIENCES(state, payload) {
    state.filter.audiences = payload;
  },
  SET_TYPES(state, payload) {
    state.filter.types = payload;
  },
  SET_GEMEENTES(state, payload) {
    state.filter.gemeentes = payload;
  },
  SET_AREAS(state, payload) {
    state.filter.areas = payload;
  },
  SET_RANGES(state, payload) {
    state.filter.ranges = payload;
  },
  SET_SEARCHES(state, payload) {
    state.filter.searches = payload;
  },
  SET_PERIODS(state, payload) {
    state.filter.periods = payload;
  },
  SET_PAGE_NR(state, payload) {
    state.filter.page = payload;
  },
  SET_VACANCIES(state, payload) {
    state.items = payload;
  },
  SET_TOTAL(state, payload) {
    state.total = parseInt(payload['pagination-count'], 10);
  },
  SET_PAGE_LIMIT(state, payload) {
    state.pageLimit = parseInt(payload['pagination-limit'], 10);
  },
  SET_ACTIVE(state, payload) {
    state.filterActive = payload;
  },
};
