<template>
  <v-app>
    <skip-content/>
    <app-header/>
    <drawer/>
    <div class="greyLight header-shadow">
      <v-container py-0>
        <v-layout wrap>
          <v-flex d-flex xs12 align-center py-4>
            <h1 v-if="gift">Jouw geschenk aanvragen</h1>
            <h1 v-else>Inloggen / registreren bij Mijn KLiK</h1>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <v-content>
      <v-container id="main" tabindex="-1" grid-list-xl>
        <nuxt />
      </v-container>
    </v-content>
    <app-footer/>
  </v-app>
</template>

<script>
import Footer from '~/components/Footer';
import Header from '~/components/Header';
import Drawer from '~/components/Drawer';
import Breadcrumbs from '~/components/Breadcrumbs';
import HeadingPhotos from '~/components/HeadingPhotos';
import SkipContent from '~/components/SkipContent';

export default {
  components: {
    Drawer,
    'app-footer': Footer,
    'app-header': Header,
    SkipContent,
    HeadingPhotos,
    Breadcrumbs,
  },
  computed: {
    gift() {
      return this.$route.query && this.$route.query.redirect && this.$route.query.redirect === '/geschenk';
    },
  },
};
</script>

<style lang="stylus" scoped>
  .header-shadow
    box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.3);
</style>
