<script>
import HeaderContent from '~/components/HeaderContent';
import Drawer from '~/components/Drawer';

export default {
  functional: true,
  render(createElement) {
    const first = createElement(HeaderContent);
    const second = createElement(Drawer);

    return [first, second];
  },
};
</script>
