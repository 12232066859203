export const state = () => ({
  filter: {
    gemeentes: [],
    areas: [],
    categories: [],
    searches: [],
    page: 1,
  },
  items: [],
  total: 0,
  pageLimit: 0,
  filterActive: false,
});

export const actions = {
  resetFilters({commit}) {
    commit('SET_GEMEENTES', []);
    commit('SET_AREAS', []);
    commit('SET_CATEGORIES', []);
    commit('SET_SEARCHES', []);
    commit('SET_PAGE_NR', 1);
    commit('SET_ACTIVE', false);
  },
  async getOffers({commit, app, state}) {
    let url = `/aanbiedingen?page=${state.filter.page}`;

    if (state.filter.searches.length) {
      url += `&s=${encodeURIComponent(state.filter.searches)}`;
    }
    if (state.filter.categories.length) {
      url += `&category=${state.filter.categories.join('|')}`;
    }

    if (state.filter.areas.length) {
      url += `&gemeentes=${state.filter.areas.join('|')}`;
    }

    try {
      const {data, headers} = await this.$axios.get(url);

      commit('SET_OFFERS', data);
      commit('SET_TOTAL', headers);
      commit('SET_PAGE_LIMIT', headers);
      commit('SET_ACTIVE', true);
    } catch (e) {
      app.$bugsnag.notify(e);
    }
  },
};

export const mutations = {
  // SET_AUDIENCES(state, payload) {
  SET_GEMEENTES(state, payload) {
    state.filter.gemeentes = payload;
  },
  SET_AREAS(state, payload) {
    state.filter.areas = payload;
  },
  SET_CATEGORIES(state, payload) {
    state.filter.categories = payload;
  },
  SET_SEARCHES(state, payload) {
    state.filter.searches = payload;
  },
  SET_PAGE_NR(state, payload) {
    state.filter.page = payload;
  },
  SET_OFFERS(state, payload) {
    state.items = payload;
  },
  SET_TOTAL(state, payload) {
    state.total = parseInt(payload['pagination-count'], 10);
  },
  SET_PAGE_LIMIT(state, payload) {
    state.pageLimit = parseInt(payload['pagination-limit'], 10);
  },
  SET_ACTIVE(state, payload) {
    state.filterActive = payload;
  },
};
