<template>
  <v-app>
    <skip-content/>
    <app-header/>
    <drawer/>
    <v-content id="main" tabindex="-1">
      <HeadingPhotos/>
      <v-container>
        <Breadcrumbs/>
        <nuxt />
      </v-container>
    </v-content>
    <app-footer/>
  </v-app>
</template>

<script>
import Footer from '~/components/Footer';
import Header from '~/components/Header';
import Drawer from '~/components/Drawer';
import Breadcrumbs from '~/components/Breadcrumbs';
import HeadingPhotos from '~/components/HeadingPhotos';
import SkipContent from '~/components/SkipContent';

export default {
  components: {
    Drawer,
    'app-footer': Footer,
    'app-header': Header,
    SkipContent,
    HeadingPhotos,
    Breadcrumbs,
  },
};
</script>
