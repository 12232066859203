<template>
  <div class="greyLight header-shadow">
    <v-container py-0>
      <v-layout row wrap>
        <v-flex py-3>
          <i>Welkom terug {{ user.name }}</i>
          <h1>{{ page.title }}</h1>
        </v-flex>
        <v-flex class="text-xs-right">
          <v-btn :to="{name: 'contact'}" class="ma-0 font-weight-bold white--text" color="#a1b5b9" depressed nuxt style="border-radius: 0">
            Vraag het aan KLIK
            <v-icon small right>far fa-long-arrow-alt-right</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  computed: mapState(['page']),
};
</script>
