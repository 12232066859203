import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3f7461e4 = () => interopDefault(import('../pages/academie/index.vue' /* webpackChunkName: "pages/academie/index" */))
const _12a1ee5e = () => interopDefault(import('../pages/agenda/index.vue' /* webpackChunkName: "pages/agenda/index" */))
const _7f9405d7 = () => interopDefault(import('../pages/bedank-een-vrijwilliger.vue' /* webpackChunkName: "pages/bedank-een-vrijwilliger" */))
const _4da15fce = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _5b42dec2 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _74c09b6d = () => interopDefault(import('../pages/geschenk/index.vue' /* webpackChunkName: "pages/geschenk/index" */))
const _5e932eb3 = () => interopDefault(import('../pages/inloggen.vue' /* webpackChunkName: "pages/inloggen" */))
const _aac7c7e4 = () => interopDefault(import('../pages/instellingen.vue' /* webpackChunkName: "pages/instellingen" */))
const _430ff942 = () => interopDefault(import('../pages/kennisbank.vue' /* webpackChunkName: "pages/kennisbank" */))
const _e54b78ca = () => interopDefault(import('../pages/maassluis-bedankt/index.vue' /* webpackChunkName: "pages/maassluis-bedankt/index" */))
const _5322df74 = () => interopDefault(import('../pages/my-aanbiedingen.vue' /* webpackChunkName: "pages/my-aanbiedingen" */))
const _3090086b = () => interopDefault(import('../pages/my-academies.vue' /* webpackChunkName: "pages/my-academies" */))
const _509b2db8 = () => interopDefault(import('../pages/my-gifts.vue' /* webpackChunkName: "pages/my-gifts" */))
const _6c055492 = () => interopDefault(import('../pages/my-vacatures.vue' /* webpackChunkName: "pages/my-vacatures" */))
const _a2b1b268 = () => interopDefault(import('../pages/nieuws/index.vue' /* webpackChunkName: "pages/nieuws/index" */))
const _f147eada = () => interopDefault(import('../pages/ondernemers/index.vue' /* webpackChunkName: "pages/ondernemers/index" */))
const _0abb24ae = () => interopDefault(import('../pages/organisaties/index.vue' /* webpackChunkName: "pages/organisaties/index" */))
const _3ed45416 = () => interopDefault(import('../pages/over.vue' /* webpackChunkName: "pages/over" */))
const _54a860d3 = () => interopDefault(import('../pages/pas/index.vue' /* webpackChunkName: "pages/pas/index" */))
const _4a399105 = () => interopDefault(import('../pages/profiel.vue' /* webpackChunkName: "pages/profiel" */))
const _1fb7b92b = () => interopDefault(import('../pages/schiedam-bedankt/index.vue' /* webpackChunkName: "pages/schiedam-bedankt/index" */))
const _3c7e6e87 = () => interopDefault(import('../pages/vacatures/index.vue' /* webpackChunkName: "pages/vacatures/index" */))
const _2540eece = () => interopDefault(import('../pages/vestigingen/index.vue' /* webpackChunkName: "pages/vestigingen/index" */))
const _c84cf804 = () => interopDefault(import('../pages/vlaardingen-bedankt/index.vue' /* webpackChunkName: "pages/vlaardingen-bedankt/index" */))
const _1358010b = () => interopDefault(import('../pages/vrijwilligerspas.vue' /* webpackChunkName: "pages/vrijwilligerspas" */))
const _03ef53ee = () => interopDefault(import('../pages/vrijwilligerswerk.vue' /* webpackChunkName: "pages/vrijwilligerswerk" */))
const _4b31be56 = () => interopDefault(import('../pages/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/index" */))
const _11019156 = () => interopDefault(import('../pages/zoekresultaten.vue' /* webpackChunkName: "pages/zoekresultaten" */))
const _50285d70 = () => interopDefault(import('../pages/academie/create.vue' /* webpackChunkName: "pages/academie/create" */))
const _c74cd55a = () => interopDefault(import('../pages/e-learning/create.vue' /* webpackChunkName: "pages/e-learning/create" */))
const _4b071cf1 = () => interopDefault(import('../pages/geschenk/create.vue' /* webpackChunkName: "pages/geschenk/create" */))
const _583c7a40 = () => interopDefault(import('../pages/geschenk/gebruikt.vue' /* webpackChunkName: "pages/geschenk/gebruikt" */))
const _5037ab70 = () => interopDefault(import('../pages/geschenk/ongeldig.vue' /* webpackChunkName: "pages/geschenk/ongeldig" */))
const _089187ef = () => interopDefault(import('../pages/maassluis-bedankt/geschenk.vue' /* webpackChunkName: "pages/maassluis-bedankt/geschenk" */))
const _6818044b = () => interopDefault(import('../pages/pas/create.vue' /* webpackChunkName: "pages/pas/create" */))
const _9af52f42 = () => interopDefault(import('../pages/schiedam-bedankt/geschenk.vue' /* webpackChunkName: "pages/schiedam-bedankt/geschenk" */))
const _7b03ad17 = () => interopDefault(import('../pages/vacatures/create.vue' /* webpackChunkName: "pages/vacatures/create" */))
const _12f748ac = () => interopDefault(import('../pages/vlaardingen-bedankt/geschenk.vue' /* webpackChunkName: "pages/vlaardingen-bedankt/geschenk" */))
const _82607c34 = () => interopDefault(import('../pages/wachtwoord-vergeten/reset/_token.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/reset/_token" */))
const _24d0706e = () => interopDefault(import('../pages/academie/_slug/index.vue' /* webpackChunkName: "pages/academie/_slug/index" */))
const _160f30ee = () => interopDefault(import('../pages/agenda/_slug.vue' /* webpackChunkName: "pages/agenda/_slug" */))
const _66aed5d6 = () => interopDefault(import('../pages/geschenk/_id.vue' /* webpackChunkName: "pages/geschenk/_id" */))
const _a61ef4f8 = () => interopDefault(import('../pages/nieuws/_slug.vue' /* webpackChunkName: "pages/nieuws/_slug" */))
const _f4b52d6a = () => interopDefault(import('../pages/ondernemers/_slug.vue' /* webpackChunkName: "pages/ondernemers/_slug" */))
const _09048366 = () => interopDefault(import('../pages/organisaties/_slug.vue' /* webpackChunkName: "pages/organisaties/_slug" */))
const _3f6f2882 = () => interopDefault(import('../pages/vacatures/_slug/index.vue' /* webpackChunkName: "pages/vacatures/_slug/index" */))
const _238a4d86 = () => interopDefault(import('../pages/vestigingen/_slug.vue' /* webpackChunkName: "pages/vestigingen/_slug" */))
const _6f924338 = () => interopDefault(import('../pages/wachtwoord-instellen/_token.vue' /* webpackChunkName: "pages/wachtwoord-instellen/_token" */))
const _2d24de46 = () => interopDefault(import('../pages/academie/_slug/aanmeldingen.vue' /* webpackChunkName: "pages/academie/_slug/aanmeldingen" */))
const _3717be63 = () => interopDefault(import('../pages/academie/_slug/edit.vue' /* webpackChunkName: "pages/academie/_slug/edit" */))
const _16544cc6 = () => interopDefault(import('../pages/e-learning/_slug/edit.vue' /* webpackChunkName: "pages/e-learning/_slug/edit" */))
const _1d98d564 = () => interopDefault(import('../pages/geschenk/_slug/edit.vue' /* webpackChunkName: "pages/geschenk/_slug/edit" */))
const _225b0084 = () => interopDefault(import('../pages/pas/_slug/edit.vue' /* webpackChunkName: "pages/pas/_slug/edit" */))
const _42363aec = () => interopDefault(import('../pages/vacatures/_slug/edit.vue' /* webpackChunkName: "pages/vacatures/_slug/edit" */))
const _bdf64a1a = () => interopDefault(import('../pages/vacatures/_slug/sollicitaties.vue' /* webpackChunkName: "pages/vacatures/_slug/sollicitaties" */))
const _c821ee00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _357f2f58 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/academie",
    component: _3f7461e4,
    name: "academie"
  }, {
    path: "/agenda",
    component: _12a1ee5e,
    name: "agenda"
  }, {
    path: "/bedank-een-vrijwilliger",
    component: _7f9405d7,
    name: "bedank-een-vrijwilliger"
  }, {
    path: "/contact",
    component: _4da15fce,
    name: "contact"
  }, {
    path: "/dashboard",
    component: _5b42dec2,
    name: "dashboard"
  }, {
    path: "/geschenk",
    component: _74c09b6d,
    name: "geschenk"
  }, {
    path: "/inloggen",
    component: _5e932eb3,
    name: "inloggen"
  }, {
    path: "/instellingen",
    component: _aac7c7e4,
    name: "instellingen"
  }, {
    path: "/kennisbank",
    component: _430ff942,
    name: "kennisbank"
  }, {
    path: "/maassluis-bedankt",
    component: _e54b78ca,
    name: "maassluis-bedankt"
  }, {
    path: "/my-aanbiedingen",
    component: _5322df74,
    name: "my-aanbiedingen"
  }, {
    path: "/my-academies",
    component: _3090086b,
    name: "my-academies"
  }, {
    path: "/my-gifts",
    component: _509b2db8,
    name: "my-gifts"
  }, {
    path: "/my-vacatures",
    component: _6c055492,
    name: "my-vacatures"
  }, {
    path: "/nieuws",
    component: _a2b1b268,
    name: "nieuws"
  }, {
    path: "/ondernemers",
    component: _f147eada,
    name: "ondernemers"
  }, {
    path: "/organisaties",
    component: _0abb24ae,
    name: "organisaties"
  }, {
    path: "/over",
    component: _3ed45416,
    name: "over"
  }, {
    path: "/pas",
    component: _54a860d3,
    name: "pas"
  }, {
    path: "/profiel",
    component: _4a399105,
    name: "profiel"
  }, {
    path: "/schiedam-bedankt",
    component: _1fb7b92b,
    name: "schiedam-bedankt"
  }, {
    path: "/vacatures",
    component: _3c7e6e87,
    name: "vacatures"
  }, {
    path: "/vestigingen",
    component: _2540eece,
    name: "vestigingen"
  }, {
    path: "/vlaardingen-bedankt",
    component: _c84cf804,
    name: "vlaardingen-bedankt"
  }, {
    path: "/vrijwilligerspas",
    component: _1358010b,
    name: "vrijwilligerspas"
  }, {
    path: "/vrijwilligerswerk",
    component: _03ef53ee,
    name: "vrijwilligerswerk"
  }, {
    path: "/wachtwoord-vergeten",
    component: _4b31be56,
    name: "wachtwoord-vergeten"
  }, {
    path: "/zoekresultaten",
    component: _11019156,
    name: "zoekresultaten"
  }, {
    path: "/academie/create",
    component: _50285d70,
    name: "academie-create"
  }, {
    path: "/e-learning/create",
    component: _c74cd55a,
    name: "e-learning-create"
  }, {
    path: "/geschenk/create",
    component: _4b071cf1,
    name: "geschenk-create"
  }, {
    path: "/geschenk/gebruikt",
    component: _583c7a40,
    name: "geschenk-gebruikt"
  }, {
    path: "/geschenk/ongeldig",
    component: _5037ab70,
    name: "geschenk-ongeldig"
  }, {
    path: "/maassluis-bedankt/geschenk",
    component: _089187ef,
    name: "maassluis-bedankt-geschenk"
  }, {
    path: "/pas/create",
    component: _6818044b,
    name: "pas-create"
  }, {
    path: "/schiedam-bedankt/geschenk",
    component: _9af52f42,
    name: "schiedam-bedankt-geschenk"
  }, {
    path: "/vacatures/create",
    component: _7b03ad17,
    name: "vacatures-create"
  }, {
    path: "/vlaardingen-bedankt/geschenk",
    component: _12f748ac,
    name: "vlaardingen-bedankt-geschenk"
  }, {
    path: "/wachtwoord-vergeten/reset/:token",
    component: _82607c34,
    name: "wachtwoord-vergeten-reset-token"
  }, {
    path: "/academie/:slug",
    component: _24d0706e,
    name: "academie-slug"
  }, {
    path: "/agenda/:slug",
    component: _160f30ee,
    name: "agenda-slug"
  }, {
    path: "/geschenk/:id",
    component: _66aed5d6,
    name: "geschenk-id"
  }, {
    path: "/nieuws/:slug",
    component: _a61ef4f8,
    name: "nieuws-slug"
  }, {
    path: "/ondernemers/:slug",
    component: _f4b52d6a,
    name: "ondernemers-slug"
  }, {
    path: "/organisaties/:slug",
    component: _09048366,
    name: "organisaties-slug"
  }, {
    path: "/vacatures/:slug",
    component: _3f6f2882,
    name: "vacatures-slug"
  }, {
    path: "/vestigingen/:slug",
    component: _238a4d86,
    name: "vestigingen-slug"
  }, {
    path: "/wachtwoord-instellen/:token?",
    component: _6f924338,
    name: "wachtwoord-instellen-token"
  }, {
    path: "/academie/:slug/aanmeldingen",
    component: _2d24de46,
    name: "academie-slug-aanmeldingen"
  }, {
    path: "/academie/:slug/edit",
    component: _3717be63,
    name: "academie-slug-edit"
  }, {
    path: "/e-learning/:slug?/edit",
    component: _16544cc6,
    name: "e-learning-slug-edit"
  }, {
    path: "/geschenk/:slug/edit",
    component: _1d98d564,
    name: "geschenk-slug-edit"
  }, {
    path: "/pas/:slug/edit",
    component: _225b0084,
    name: "pas-slug-edit"
  }, {
    path: "/vacatures/:slug/edit",
    component: _42363aec,
    name: "vacatures-slug-edit"
  }, {
    path: "/vacatures/:slug/sollicitaties",
    component: _bdf64a1a,
    name: "vacatures-slug-sollicitaties"
  }, {
    path: "/",
    component: _c821ee00,
    name: "index"
  }, {
    path: "/*",
    component: _357f2f58,
    name: "*"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
