<template>
  <div>
    <div class="teaser teaser-primary pa-4" v-html="page.blok_1"/>
    <div class="teaser teaser-secondary pa-4 mt-4" v-html="page.blok_2"/>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
