<template>
  <div class="hidden-print overflow-hidden">
    <v-container v-if="!small" class="teaser-about primary--text hidden-sm-and-down" px-0 py-5 tag="section" fluid>

      <v-layout v-if="!authenticated" row wrap>
        <v-flex class="footer-left" md6 xs-12 px-5>
          <v-layout row wrap justify-center align-content-center>
            <v-flex offset-xs2 offset-md5 offset-xl1 md5 xl3>
              <h2 class="mb-3">{{ settings.footer_header }}</h2>
              <v-btn :block="$vuetify.breakpoint.smAndDown.isMounted" :to="{name: 'contact'}" color="primary"
                     depressed nuxt class="ma-0">
                {{ settings.footer_cta }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="footer-right" md6 pl-5 pt-5>
          <h2 class="mb-4">{{ settings.footer_usp_header }}</h2>
          <div class="checklist" v-html="settings.footer_usps"/>
        </v-flex>
      </v-layout>
    </v-container>
    <v-card v-if="!small && !authenticated" flat color="tertiary" class="ma-4 hidden-md-and-up white--text">
      <v-card-text>
        <h2 class="mb-3">{{ settings.footer_header }}</h2>
        <v-btn :block="$vuetify.breakpoint.smAndDown.isMounted" :to="{name: 'contact'}" color="grey-light"
               depressed nuxt class="ma-0">
          {{ settings.footer_cta }}
        </v-btn>
      </v-card-text>
    </v-card>
    <v-card v-if="!small" flat color="tertiary" class="ma-4 hidden-md-and-up white--text">
      <v-card-text>
        <h2>{{ settings.footer_usp_header }}</h2>
        <div class="checklist" v-html="settings.footer_usps"/>
      </v-card-text>
    </v-card>

    <v-footer tag="footer">
      <v-container>
        <v-layout row wrap>

          <v-flex :class="[$vuetify.breakpoint.smAndDown.isMounted ? 'my-4': 'my-0',authenticated ? 'md12' : 'md7 ']"
                  xs12>

            <v-layout wrap contacts>
              <v-flex>
                <img :class="{'mb-0': $vuetify.breakpoint.smAndDown, 'mb-4': $vuetify.breakpoint.mdAndUp}"
                     src="~/assets/img/logo.png" alt="Klik Vrijwilligers">
              </v-flex>

              <v-flex v-for="(vestiging, index) in vestigingen.data.slice(0,3)" :key="index"
                      :class="['details', authenticated ? 'md3' : 'md6']">

                <h1 class="mb-4">{{ vestiging.city }}</h1>
                <p class="mb-0">{{ vestiging.bezoekadres }}</p>
                <p class="mb-4">{{ vestiging.postalcode }} {{ vestiging.city }}</p>
                <p class="mb-0">{{ vestiging.email }}</p>
                <p><a :href="`tel:${vestiging.phone}`" class="theme-white--text no-underline"
                      target="_blank" rel="nofollow noopener">
                  {{ vestiging.phone }}
                </a></p>

              </v-flex>

            </v-layout>
          </v-flex>
          <v-flex v-if="!authenticated" xs12 md5 class="newsletter mt-4 mt-sm-0">
            <newsletter/>
          </v-flex>
        </v-layout>
        <v-layout>
          <footer-menu/>
        </v-layout>
      </v-container>
    </v-footer>
    <v-snackbar v-model="snackbarActive" :timeout="16000" role="alert" color="success" top multi-line>
      {{ snackbar.message }}
    </v-snackbar>
    <cookie-bar/>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import FooterMenu from '~/components/FooterMenu';
import Newsletter from '~/components/Newsletter';
import CookieBar from '~/components/CookieBar';

export default {
  components: {
    FooterMenu,
    Newsletter,
    CookieBar,
  },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      settings: state => state.settings,
      snackbar: state => state.validation.snackbar,
      vestigingen: state => state.vestigingen,
    }),
    mapsUrl() {
      return 'https://www.google.nl/maps/place/Klik+Vrijwilligers/@51.9092702,4.3373648,17z/data=!3m1!4b1!4m5!3m4!1s0x47c44af1f7f7d581:0x7d47a77618159991!8m2!3d51.9092702!4d4.3395535';
    },
    snackbarActive: {
      set(snackbarActive) {
        this.$store.commit('validation/SET_SNACKBAR_ACTIVE', snackbarActive);
      },
      get() {
        return this.snackbar.active;
      },
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/style/colors'

footer
  padding 1rem 0
  background secondary
  height auto !important

.v-footer
  color theme-white
  background linear-gradient(to top left, #153a63 50%, #002854 50%)

.details a
  color theme-white
  font-weight 400
  text-decoration underline

  &.no-underline
    text-decoration none
    transition all 200ms

    &:hover
      color error

.teaser-about .row
  height 384px !important

.footer-left
  background url('~assets/img/footer_1.png') top no-repeat
  height 100%

.footer-right
  background url('~assets/img/footer_2.png') top no-repeat
  height 100%
  background-color grey-light

</style>
