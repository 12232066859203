<template>
  <v-layout row wrap>

    <v-flex v-for="(item, index) in menuFooter" :key="index">
      <a v-if="item.url.startsWith('http')" :href="item.url" :target="item.target" rel="noopener noreferrer">{{ item.text }}</a>
      <nuxt-link v-else :to="item.url">{{ item.text }}</nuxt-link>
    </v-flex>

  </v-layout>
</template>

<script>
import {mapState} from 'vuex';

export default {
  computed: mapState({
    menuFooter: state => state.menuFooter,
  }),
};
</script>

<style lang="stylus" scoped>
@import '~assets/style/colors'
.layout
  margin-top 1rem

a
  font-weight 400
  text-decoration underline
  color theme-white

  &:hover
    color error

</style>
