import objectToFormData from 'object-to-formdata';

export default {
  methods: {
    focusFirstError(component, offset = 240) {
      component.$nextTick(() => {
        //set aria role (dirty fix for WCAG)
        let elements = component.$el.querySelectorAll('.v-messages__message');

        elements.forEach((el) => {
          el.setAttribute('role', 'alert');
        })

        // go to first error
        let element = component.$el.querySelector('.error--text');

        if (!element) {
          return;
        }

        component.$vuetify.goTo(element, {
          offset: offset,
        });
      });
    },
    objectToFormData(payload) {
      return objectToFormData(this.removeEmptyArrays(payload));
    },
    removeEmptyArrays(data) {
      let newData = {};

      for (let key in data) {
        if (!data.hasOwnProperty(key)) {
          continue;
        }

        let isArray = Array.isArray(data[key]);

        if (!isArray || (isArray && data[key].length !== 0)) {
          newData[key] = data[key];
        }
      }

      return newData;
    },
  },
};
