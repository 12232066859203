<template>
  <header class="bg-grey-light header-sticky">
    <v-container class="px0 py-1" hidden-sm-and-down>
      <v-layout row wrap justify-space-between align-center>
        <v-flex shrink>
          <nuxt-link to="/vestigingen" class="no-underline font-italic font-weight-bold body-1">
            Klik hier voor de openingstijden van onze vestigingen
            <v-icon small color="primary" class="pl-3">far fa-long-arrow-alt-right</v-icon>
          </nuxt-link>
        </v-flex>
        <v-flex shrink>
          <v-btn :to="{name: 'organisaties'}" flat color="primary" class="ma-0 font-weight-bold body-1" nuxt>Voor organisaties en initiatieven
            <v-icon right small>far fa-long-arrow-alt-right</v-icon>
          </v-btn>
          <v-btn v-if="authenticated" :to="{name: 'dashboard'}" flat class="ma-0 font-weight-bold body-1" nuxt>
            <v-icon left small>fas fa-user</v-icon>
            Mijn KLiK
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
    <v-toolbar color="white" class="toolbar" flat>
      <v-container class="py-3" hidden-sm-and-down>
        <v-layout row wrap justify-space-between align-center>
          <v-flex md3>
            <v-layout>
              <v-flex md6 align-self-center>
                <nuxt-link to="/" class="logo">
                  <img :alt="settings.name" src="~/assets/img/logo_header.png">
                </nuxt-link>
              </v-flex>
              <v-flex align-self-center class="font-italic font-weight-medium pl-4">
                <p class="ma-0 tertiary--text">Maassluis</p>
                <p class="ma-0 secondary--text">Vlaardingen</p>
                <p class="ma-0 accent--text">Schiedam</p>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex shrink hidden-sm-and-down>
            <v-toolbar-items>
              <nuxt-link v-for="(item, index) in menuMain" :to="item.url" :key="index" class="px-2 no-underline">
                <span>{{ item.text }}</span>
              </nuxt-link>
            </v-toolbar-items>
          </v-flex>
          <v-flex shrink text-xs-right hidden-sm-and-down>
            <template v-if="authenticated">
              <v-layout align-center>
                <v-layout column text-right mr-2>
                  <nuxt-link :to="{name: 'dashboard'}" class="btn btn-link body-1 no-underline hidden-lg-and-up">
                    {{ user.first_name }}
                  </nuxt-link>
                  <nuxt-link :to="{name: 'dashboard'}" class="btn btn-link body-1 no-underline hidden-md-and-down">
                    {{ user.name }}
                  </nuxt-link>
                  <nuxt-link :to="{name: 'dashboard'}" class="body-1">Dashboard</nuxt-link>
                </v-layout>
                <v-menu v-model="activeMenu" transition="slide-y-transition" bottom offset-y left>
                  <v-layout slot="activator" align-center>
                    <v-avatar>
                      <img :alt="user.name" :src="user.avatar">
                    </v-avatar>
                    <v-icon small color="primary" class="pl-2">far fa-angle-down</v-icon>
                  </v-layout>
                  <v-list>
                    <v-list-tile v-for="(item, i) in menu" :key="i" :to="item.slug">
                      <v-list-tile-title>{{ item.label }}</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="logout">
                      <v-list-tile-title>Uitloggen</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </v-layout>
            </template>
            <template v-else>
              <v-btn :to="{name: 'dashboard'}" nuxt color="primary" depressed class="mx-0 login">Mijn KLik
                <v-icon small right>fas fa-user</v-icon>
              </v-btn>
            </template>
          </v-flex>
        </v-layout>
      </v-container>
    </v-toolbar>
    <v-expansion-panel v-model="accordion" class="accordion elevation-0 hidden-md-and-up">
      <v-expansion-panel-content>
        <v-icon v-if="!accordion" slot="actions" color="primary" class="transition-none icon-small">fas fa-search
        </v-icon>
        <v-icon v-else slot="actions" color="primary" class="transition-none">fal fa-times</v-icon>
        <div slot="header">
          <v-btn flat class="mx-0 pl-0 primary--text" @click.stop="drawerActive = !drawerActive, accordion = null">
            <v-icon left class="px-2 py-1 primary" color="white">fal fa-bars</v-icon>
            <strong>Menu</strong>
          </v-btn>
        </div>
        <v-container py-0>
          <v-divider class="white"/>
          <form class="layout justify-center" @submit.prevent>
            <v-layout>
              <v-text-field id="zoek-vacature" v-model="q" hide-details single-line label="Zoek op vacature..."
                            class="pt-0 text-field" @keyup.enter.native="q !== '' && $refs.submit.$el.click()"/>
              <v-btn ref="submit" :to="{name: 'vacatures', params: {q: q}}" icon nuxt>
                <v-icon color="primary">far fa-long-arrow-alt-right</v-icon>
              </v-btn>
            </v-layout>
          </form>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </header>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data() {
    return {
      accordion: null,
      activeMenu: false,
      q: '',
      menu: [
        {
          label: 'Profiel bewerken',
          slug: '/profiel',
        },
        {
          label: 'Instellingen',
          slug: '/instellingen',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      settings: state => state.settings,
      drawer: state => state.drawer,
      menuMain: state => state.menuMain,
    }),
    drawerActive: {
      set(drawerActive) {
        this.$store.commit('SET_DRAWER', drawerActive);
      },
      get() {
        return this.drawer;
      },
    },
    mapsUrl() {
      return 'https://www.google.nl/maps/place/Klik+Vrijwilligers/@51.9092702,4.3373648,17z/data=!3m1!4b1!4m5!3m4!1s0x47c44af1f7f7d581:0x7d47a77618159991!8m2!3d51.9092702!4d4.3395535';
    },
  },
  methods: {
    async logout() {
      await this.$auth.logout();
      // this.$router.push({path: '/'});
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/style/colors'

.header-sticky
  position -webkit-sticky
  position sticky
  top 0
  box-shadow 0 0 3px 0 rgba(0, 0, 0, 0.2)
  overflow hidden
  z-index 7

  >>> .v-toolbar__content
    height auto !important
    padding-left 0
    padding-right 0


a.no-underline
  text-decoration none

.nuxt-link-exact-active span, .nuxt-link-active:not(:first-of-type) span
  border-bottom 2px primary solid
  font-weight bold

.text-field
  >>> .v-input__slot
    padding 4px 0 8px

    &::before
      border none !important

  >>> label
    color primary-light

.accordion >>>
.v-expansion-panel__header, .v-expansion-panel__body
  background-color grey-light
  padding-top 0
  padding-bottom 0

.nav
  padding-top 112px

  >>> .v-list__tile--active
    font-weight 700


.toolbar >>> .v-toolbar__content
  justify-content center

.transition-none
  transition none !important

.icon-small
  font-size 20px

hr.thick
  border-width 3px 0 0 0

</style>
