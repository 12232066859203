<template>
  <v-app>
    <skip-content/>
    <app-header/>
    <drawer/>
    <nuxt />
    <app-footer :small="true"/>
  </v-app>
</template>

<script>
import Footer from '~/components/Footer';
import Header from '~/components/Header';
import Drawer from '~/components/Drawer';
import SkipContent from '~/components/SkipContent';

export default {
  components: {
    Drawer,
    'app-footer': Footer,
    'app-header': Header,
    SkipContent,
  },
};
</script>
