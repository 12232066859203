import Vue from 'vue';
import Vuetify from 'vuetify';
//import colors from 'vuetify/es5/util/colors';
import nl from 'vuetify/es5/locale/nl';

import '@fortawesome/fontawesome-pro/css/all.css';

Vue.use(Vuetify, {
  lang: {
    locales: {nl},
    current: 'nl',
  },
  icons: {
    radioOff: 'far fa-circle',
    radioOn: 'far fa-check-circle',
    checkboxOff: 'far fa-square',
    checkboxOn: 'far fa-check-square',
    close: 'fal fa-times',
    //dropdown: 'fal fa-times',
    dropdown: 'fas fa-caret-down',
    expand: 'fas fa-caret-down',
    prev: 'far fa-arrow-alt-circle-left',
    //prev: 'fal fa-angle-left',
    next: 'far fa-arrow-alt-circle-right',
    //next: 'fal fa-angle-right',
    warning: 'fas fa-exclamation-circle',
    success: 'fas fa-check-circle',
    error: 'fas fa-exclamation-circle',
  },
  theme: {
    primary: '#002E60',
    secondary: '#008999',
    tertiary: '#2193CF',
    accent: '#F0821B',
    secaccent: '#69C7DF',
    info: '#002E60',
    success: '#008999',
    warning: '#EFA105',
    error: '#E12E2F',
    greyLight: '#EBEEF2',
    blueLight: '#EBF5F7',
    /*primary: '#121212', // a color that is not in the material colors palette
        accent: colors.grey.darken3,
        secondary: colors.amber.darken3,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3,*/
  },
});
