<template>
  <div>
    <v-alert :value="success" type="success">Het formulier is succesvol verstuurd.</v-alert>
    <form v-if="!success" method="post" class="mb-3" @submit.prevent="submit">
      <v-layout>
        <v-flex xs12 md6>
          <div class="pa-4 bg-grey-light fill-height">
            <div v-for="field in template.fields" :key="field.position">
              <label :for="`${field.name}_${id}`" class="font-weight-bold">{{ field.label }}</label>
              <v-text-field
                v-if="field.type === 'text'"
                v-model="form[field.name]"
                :error-messages="errors[field.name]"
                :required="!!field.required"
                :id="`${field.name}_${id}`"
                solo
                flat
              />
              <v-text-field
                v-if="field.type === 'email'"
                v-model="form[field.name]"
                :error-messages="errors[field.name]"
                :required="!!field.required"
                :id="field.name + '_' + id"
                type="email"
                solo
                flat
              />
              <v-text-field
                v-if="field.type === 'number'"
                v-model="form[field.name]"
                :error-messages="errors[field.name]"
                :required="!!field.required"
                :id="field.name + '_' + id"
                type="number"
                solo
                flat
              />
              <v-select v-if="field.type === 'select'"
                        :items="field.options"
                        :error-messages="errors[field.name]"
                        v-model="form[field.name]"
                        :required="!!field.required"
                        :id="field.name + '_' + id"
                        solo
                        flat
              />
              <v-textarea v-if="field.type === 'textarea'"
                          v-model="form[field.name]"
                          :error-messages="errors[field.name]"
                          :required="!!field.required"
                          :id="field.name + '_' + id"
                          solo
                          flat
              />
              <template v-if="field.type === 'checkbox'">
                <v-checkbox v-for="(option, index) in field.options"
                            v-model="form[field.name]"
                            :label="option"
                            :value="option"
                            :key="index"
                            :hide-details="index < (field.options.length - 1)"
                            :error="errors[field.name] && (errors[field.name].length > 0)"
                            multiple
                />
              </template>
            </div>
            <v-btn v-if="template.fields" type="submit" round depressed color="accent" class="ma-0">
              Versturen
              <v-icon right small>far fa-long-arrow-alt-right</v-icon>
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
    </form>
  </div>
</template>

<script>
import commonForm from '~/plugins/mixins/form';

export default {
  mixins: [commonForm],
  props: {
    id: {
      type: Number,
      default: 0,
    },
    html: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      success: false,
      template: {},
      form: {},
    };
  },
  async mounted() {
    const fields = await this.$axios.get('/forms/' + this.id);

    this.template = fields.data.data;
  },
  methods: {
    async submit() {
      try {
        await this.$axios.post('/forms/' + this.id, this.form);

        this.success = true;
      } catch (e) {
        this.focusFirstError(this);
      }
    },
  },
};
</script>
