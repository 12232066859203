export const state = () => ({
  errors: {},
  snackbar: {
    active: false,
    message: 'Succesvol opgeslagen',
  },
});

export const getters = {
  errors(state) {
    return state.errors;
  },
};

export const mutations = {
  SET_VALIDATION_ERRORS(state, errors) {
    state.errors = errors;
  },
  SET_SNACKBAR_ACTIVE(state, mixed) {
    let fallback = 'Succesvol opgeslagen';

    if (typeof mixed === 'object') {
      state.snackbar.active = mixed.active !== false;
      state.snackbar.message = mixed.message || fallback;
    } else if (typeof mixed === 'boolean') {
      state.snackbar.active = mixed;
      state.snackbar.message = fallback;
    } else if (typeof mixed === 'string') {
      state.snackbar.active = true;
      state.snackbar.message = mixed || fallback;
    } else {
      state.snackbar.active = true;
      state.snackbar.message = fallback;
    }
  },
};

export const actions = {
  setErrors({commit}, errors) {
    commit('SET_VALIDATION_ERRORS', errors);
  },
  clearErrors({commit}) {
    commit('SET_VALIDATION_ERRORS', {});
  },
};
