import Vue from 'vue';
import Lodash from 'lodash';
// import filter from 'lodash.filter';
// import sortBy from 'lodash.sortBy';

/*let _ = {
    filter,
    sortBy,
};*/

let _ = Lodash;

Vue.prototype._ = _;

export default ({}, inject) => {
  inject('_', _);
};
