import _ from 'lodash';

export const state = () => ({
  release: process.env.RELEASE,
  settings: {},
  drawer: false,
  people: {},
  menuMain: {},
  menuFooter: {},
  page: {},
  vestigingen: [],
  coronaDialogSeen: false,
});

export const getters = {
  getPeople: state => count => {
    return _.shuffle(state.people).slice(0, count);
  },
  getFeaturedPerson: state => {
    return _.sample(_.filter(state.people, 'featured')) || {};
  },
};

export const actions = {
  async nuxtServerInit({dispatch}) {
    await dispatch('storeDispatchFunc');
  },

  async storeDispatchFunc({commit}) {
    const [settings, people, menuMain, menuFooter, vestigingen] = await Promise.all([
      this.$axios.get(`/settings`),
      this.$axios.get(`/pasfotos`),
      this.$axios.get(`/menu/main`),
      this.$axios.get(`/menu/footer`),
      this.$axios.get(`/vestigingen`),
    ]);
    commit('SET_SETTINGS', settings.data);
    commit('SET_PEOPLE', people.data);
    commit('SET_MENU_MAIN', menuMain.data);
    commit('SET_MENU_FOOTER', menuFooter.data);
    commit('SET_VESTIGINGEN', vestigingen.data);
  },

  async getPage({commit}, slug) {
    const page = await this.$axios.get(`/page?segments=${slug}`);
    commit('SET_PAGE', page.data.data);
  },
};

export const mutations = {
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_PEOPLE(state, payload) {
    state.people = payload;
  },
  SET_MENU_MAIN(state, payload) {
    state.menuMain = payload;
  },
  SET_MENU_FOOTER(state, payload) {
    state.menuFooter = payload;
  },
  SET_VESTIGINGEN(state, payload) {
    state.vestigingen = payload;
  },
  SET_DRAWER(state, payload) {
    state.drawer = payload;
  },
  SET_PAGE(state, payload) {
    if (!payload.meta) {
      payload.meta = {};
    }

    state.page = payload;
  },
  CLOSE_CORONA_DIALOG(state) {
    state.coronaDialogSeen = true;
  },
};
