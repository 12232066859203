<template>
  <div class="greyLight hidden-print">
    <v-container :class="$vuetify.breakpoint.smAndDown.isMounted ? 'pa-0' : 'py-0'">
      <v-layout wrap>
        <v-flex d-flex xs12 md5 align-center class="heading">
          <h1>{{ headerText }}</h1>
        </v-flex>
        <v-flex md7 class="hidden-sm-and-down">
          <div class="grid">
            <img v-for="(person, index) in getPeople(5)"
                 :key="index"
                 :src="person.image"
                 :alt="person.title"
            >
          </div>
        </v-flex>
        <div v-if="authenticated && $route.name !== 'contact'" class="btn-absolute hidden-sm-and-down">
          <v-btn :to="{name: 'contact'}" class="ma-0 font-weight-bold white--text" color="#a1b5b9" depressed nuxt style="border-radius: 0">
            Vraag het aan KLiK
            <v-icon small right>far fa-long-arrow-alt-right</v-icon>
          </v-btn>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters(['getPeople']),
    headerText() {
      switch (this.$route.name) {
        case 'academie':
        case 'academie-slug':
          return 'Leren is leuk!';

        case 'kennisbank':
          return 'Informatie';

        case 'nieuws':
          return 'Op de hoogte';

        case 'coronahulp':
          return 'Vlaardingen helpt';

        case 'coronahulpschiedam':
          return 'Schiedam helpt';

        default:
          return 'De juiste KLiK!';
      }
    },
  },
};
</script>

<style scoped lang="stylus">
  @import '~assets/style/colors'

  .container
    position relative

  .btn-absolute
    position absolute
    top 100%
    right 30px

  @media (min-width 768px)

    .grid
      display: -ms-grid;
      display grid
      -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns repeat(5, 1fr)
      grid-gap 10px

      for index in (1..5)
        img:nth-child({index})
          -ms-grid-column index

</style>
