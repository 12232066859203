import formMixin from '~/plugins/mixins/form';

export const state = () => ({
  busy: false,
  loggedIn: false,
  strategy: 'local',
  user: false,
});

export const mutations = {
  UPDATE_USER(state, payload) {
    state.user = payload.data.user;
  },
  SET_USER(state, user) {
    state.user = Object.assign({}, state.user, user);
  },
  SET_USER_GIFT_ID(state, giftId) {
    state.user.gift_id = giftId;
  },
  SET_ORGANIZATION(state, organization) {
    state.user.organization = Object.assign({}, state.user.organization, organization);
  },
  SET_COMPANY(state, company) {
    state.user.company = Object.assign({}, state.user.company, company);
  },
  /*INIT_LOGGEDIN(state) {
        state.loggedIn = false;
        state.user = false;
        state.busy = false;
        state.redirect = '/dashboard';
    },
    INIT_LOGOUT(state) {
        state.redirect = '/';
    },*/
};

export const actions = {
  async updateUserGeneral({commit}, payload) {
    commit('UPDATE_USER', await this.$axios.post('/profiel/general', payload));
  },
  async updateOrganizationAddress({commit}, payload) {
    commit('UPDATE_USER', await this.$axios.post('/organisaties/address', payload));
  },
  async updateUserAddress({commit}, payload) {
    commit('UPDATE_USER', await this.$axios.post('/profiel/address', payload));
  },
  async updateOrganizationGeneral({commit}, payload) {
    let formData = formMixin.methods.objectToFormData(payload);

    commit(
      'UPDATE_USER',
      await this.$axios.post('/organisaties/general', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    );
  },
  async updateCompanyGeneral({commit}, payload) {
    let formData = formMixin.methods.objectToFormData(payload);

    commit(
      'UPDATE_USER',
      await this.$axios.post('/bedrijven/general', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    );
  },
  async updateAvailability({commit}, payload) {
    commit('UPDATE_USER', await this.$axios.post('/profiel/availability', payload));
  },
  async updateVarious({commit}, payload) {
    commit('UPDATE_USER', await this.$axios.post('/profiel/various', payload));
  },
  async updateUserExtended({commit}, payload) {
    commit('UPDATE_USER', await this.$axios.post('/profiel', payload));
  },
};
