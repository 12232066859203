import Vue from 'vue';

Vue.filter('slugify', value => {
  if (typeof value !== 'string') {
    return value;
  }

  value = value.replace(/^\s+|\s+$/g, ''); // trim
  value = value.toLowerCase();

  // remove accents, swap ñ for n, etc
  let from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
  let to = 'aaaaaeeeeeiiiiooooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    value = value.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  value = value
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return value;
});

Vue.filter('capitalize', val => val.toUpperCase());

Vue.filter('lowercase', val => val.toLowerCase());

Vue.filter('nl2br', text => {
  const reg = /\n/g;

  if (text) {
    let i, s = '', lines = text.split(reg), l = lines.length;

    for (i = 0; i < l; ++i) {
      s += lines[i];
      (i !== l - 1) && (s += '<br>');
    }

    return s;
  }

  return text;
});
