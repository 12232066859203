<template>

  <v-layout row wrap class="newsletter">
    <v-flex md6>
      <form method="post" class="newsletter-form" @submit.prevent="submit" >
        <v-text-field id="naam-nieuwsbrief" v-model="form.name" :error-messages="errors.name" autocomplete="name" solo
                      flat background-color="greyLight" label="Voor- en achternaam"/>
        <v-text-field id="email-nieuwsbrief" v-model="form.email" :error-messages="errors.email" autocomplete="email"
                      type="email" solo flat background-color="greyLight"
                      label="E-mailadres" style="margin-bottom: -20px"/>
        <v-checkbox id="privacy-nieuwsbrief" v-model="form.privacy" :error-messages="errors.privacy"
                    :off-icon="'fa-square-full'" :on-icon="'far fa-check-square'"
                    name="privacy-nieuwsbrief">
          <template slot="append">
            <p background-color>
              Ik ga akkoord met de
              <a slot="activator" target="_blank" href="/privacyverklaring" @click.stop>privacy statement</a>
            </p>
          </template>
        </v-checkbox>
        <v-btn
          :loading="loading"
          :block="$vuetify.breakpoint.smAndDown.isMounted"
          background-color="greyLight"
          type="submit"
          depressed

          class="ma-0 mt-2 font-weight-bold primary--text"
        >
          Aanmelden
        </v-btn>
      </form>
    </v-flex>
    <v-flex md6 justify="center" align-self-center>
      <h2 class="mb-3 pa-4 newsletter-text font-italic">Meld je aan voor onze nieuwsbrief</h2>

    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {
        name: null,
        email: null,
        privacy: null,
      },
    };
  },
  methods: {
    async submit() {
      if (this.authenticated) {
        this.$store.commit('validation/SET_SNACKBAR_ACTIVE', true);

        this.$router.push({
          name: 'instellingen',
          params: {
            email: this.email,
          },
        });
      } else {
        try {
          this.loading = true;

          await this.$axios.post('/newsletter', this.form);

          this.$store.commit('validation/SET_SNACKBAR_ACTIVE', 'Bedankt voor je aanmelding.');

          this.clearForm();
        } catch (e) {
          if (e.response.status === 400) {
            this.errors.email = 'E-mailadres is al aangemeld voor de nieuwsbrief'
          }
          console.error(e);
        } finally {
          this.loading = false;
        }
      }
    },
    clearForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = null
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/style/colors'

.newsletter
  background white
  margin-left 15px
  padding 20px 5px

.newsletter-form
  margin-left -20px !important

.newsletter-text
  color primary
  overflow-wrap break-word
  word-wrap break-word
  hyphens auto


::v-deep .fa-square-full
  color grey-light

::v-deep .fa-check-square
  color accent!important

::v-deep .v-messages.error--text
  padding-left 15px
::v-deep .v-input--checkbox .v-messages.error--text
  padding-left 30px

a:hover
  color error

</style>
