<template>
  <div class="container">
    <template v-if="error.statusCode === 404">
      <h1>Deze pagina bestaat niet</h1>
      <p>De pagina die je zoekt bestaat niet meer of heeft nooit bestaan.<br>
        Je kan je weg vervolgen door terug te keren naar de <nuxt-link to="/">homepage</nuxt-link> of via het menu naar de juiste pagina te navigeren.</p>
    </template>
    <h1 v-else>Er is een fout opgetreden</h1>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  head: {
    title: 'Pagina niet gevonden',
  },
};
</script>
