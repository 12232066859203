<template>
  <a class="skip-main" href="#main">Naar main content</a>
</template>

<script>
export default {
  name: "SkipContent",
}
</script>

<style lang="stylus" scoped>
a.skip-main
  left -999px
  position absolute
  top auto
  width 1px
  height 1px
  overflow hidden
  z-index -999

  &:focus, &:active
    color #fff
    background-color #008999
    left auto
    top auto
    width 30%
    height auto
    overflow auto
    margin 0 35%
    padding 5px
    text-align center
    font-size 1.2em
    z-index 999
</style>
